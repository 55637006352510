<template>
  <div>
    <div class="text-h6 mt-3 mb-1">{{ label }}</div>
    <div v-if="!editMode" @click="handleEdit">
      <div v-if="hasContent" v-html="value" class="readonly ? '' : 'edit-box'"></div>
      <div v-else class="readonly ? '' : 'edit-box'">
        <em class="grey--text">{{ $t('error.no_content') }}</em>
      </div>
    </div>
    <div v-show="editMode" class="mb-3">
      <v-form v-model="isFormValid" ref="form">
        <v-text-field
          ref="text_field"
          outlined
          dense
          v-model="internal"
          :rules="rules"
        />
        <v-btn 
          color="primary"
          class="mr-2"
          :loading="isSaving"
          @click="handleSave"
          small
        >
          {{ $t('label.save') }}
        </v-btn>
        <v-btn
          color="primary"
          text
          small
          @click="handleCancel"
        >
          {{ $t('label.cancel') }}
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToggableTextField',
  props: ['value', 'label', 'rules', 'readonly'],
  data() {return {
    editMode: false,
    backup: null,
    isSaving: false,
    isFormValid: true
  }},
  computed: {
    hasContent() {
      return !!this.value;
    },
    internal: {
      get() { return this.value; },
      set(val) { this.$emit('input', val);}
    }
  },
  methods: {
    handleEdit() {
      if(this.readonly) return;
      this.backup = this.value;
      this.editMode = true;
      this.$nextTick(() => this.$refs.text_field.focus());
    },
    handleCancel() {
      this.internal = this.backup;
      this.editMode = false;
    },
    handleSave() {
      if(!this.$refs.form.validate()) {
        const invalidField = this.$refs.form.$children.find((e) => !e.valid)
        if (invalidField)
          invalidField.$el.scrollIntoView({behavior: 'smooth', block: 'center'});
        return;
      }
      this.isSaving = true;
      this.$emit('save', this.value);
    
    },
    markAsSaved() {
      this.isSaving = this.editMode = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-box {
  margin: -8px;
  padding: 8px;
  border-radius: 4px;

  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
}
</style>